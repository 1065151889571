import React, { Component } from "react";
import Menu from "./Menu";
import { NavLink } from "react-router-dom";

export default class Footer extends Component {
  render() {
    const { lang } = this.props;

    return (
      <footer className="footer">
        <div className="footer_nav">
          <Menu lang={lang} />
          <p>
            <NavLink to="/privacy">{lang.privacyPolicy}</NavLink>
          </p>
        </div>
        <div className="footer__company">
          <div className="footer__company__icon" />
          <div className="footer__company__info">
            Mentoring Plus © {new Date().getFullYear()}
          </div>
        </div>
      </footer>
    );
  }
}
