import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class Menu extends Component {
  _getLink = (link) => {
    return (window.location.pathname.indexOf("/en") !== -1 ? "/en" : "") + link;
  };

  render() {
    const { lang, onClick } = this.props;

    return (
      <ul>
        <li>
          <NavLink onClick={onClick} to={this._getLink("/")}>
            {lang.menuHome}
          </NavLink>
        </li>
        {/*}
        <li>
          <NavLink onClick={onClick} to={this._getLink("/why")}>
            {lang.menuWhy}
          </NavLink>
        </li>
        {*/}
        <li>
          <NavLink onClick={onClick} to={this._getLink("/training")}>
            {lang.menuTraining}
          </NavLink>
        </li>
        <li>
          <NavLink onClick={onClick} to={this._getLink("/academy")}>
            {lang.menuAcademy}
          </NavLink>
        </li>
        <li>
          <NavLink onClick={onClick} to={this._getLink("/programs")}>
            {lang.menuPrograms}
          </NavLink>
        </li>
        <li>
          <NavLink onClick={onClick} to={this._getLink("/mentor")}>
            {lang.menuMentor}
          </NavLink>
        </li>
        <li>
          <NavLink onClick={onClick} to={this._getLink("/book")}>
            {lang.menuBook}
          </NavLink>
        </li>
        {/*}
        <li>
          <NavLink onClick={onClick} to={this._getLink("/podcast")}>
            {lang.menuPodcast}
          </NavLink>
        </li>
        {*/}
        <li>
          <NavLink onClick={onClick} to={this._getLink("/about")}>
            {lang.menuAbout}
          </NavLink>
        </li>
        <li>
          <NavLink onClick={onClick} to={this._getLink("/contact")}>
            {lang.menuContact}
          </NavLink>
        </li>
      </ul>
    );
  }
}
