import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import GA from "react-ga";
import createHistory from "history/createBrowserHistory";
import "./App.css";

import { Header, Footer } from "./layout";
import { Home, Blog, BlogArticle, Page } from "./pages";

const lang = {
  en: {
    headerContact: "Contact",
    headerMenu: "Menu",
    headerClose: "Close",
    menuHome: "Home",
    menuWhy: "Why Mentoring?",
    menuTraining: "Trainings",
    menuAcademy: "Academy",
    menuPrograms: "Programs & Services",
    menuMentor: "Need a Mentor?",
    menuStories: "Stories+",
    menuBlog: "Blog",
    menuBook: "Book",
    menuPodcast: "Podcast",
    menuAbout: "About Us",
    menuContact: "Contact Us",
    privacyPolicy: "Privacy Policy",
  },
  hr: {
    headerContact: "Kontakt",
    headerMenu: "Izbornik",
    headerClose: "Zatvori",
    menuHome: "Početna",
    menuWhy: "Zašto mentoring?",
    menuTraining: "Treninzi",
    menuAcademy: "Akademija",
    menuPrograms: "Mentorski i coaching programi",
    menuMentor: "Trebate mentora i coacha?",
    menuStories: "Priče+",
    menuBlog: "Blog",
    menuBook: "Knjiga",
    menuPodcast: "Podcast",
    menuAbout: "O nama",
    menuContact: "Kontakt",
    privacyPolicy: "Politika privatnosti",
  },
};

class App extends Component {
  constructor(props) {
    super(props);

    const history = createHistory();
    history.listen((location, action) => {
      this.trackPageview(location.pathname);
    });

    const language =
      window.location.pathname.indexOf("/en") !== -1 ? "en" : "hr";

    this.state = {
      history: history,
      language: language,
      lang: lang[language],
    };

    GA.initialize("G-HQTSZNWNXX");
    this.trackPageview(window.location.pathname);
  }

  trackPageview = (page) => {
    GA.set({ page: page });
    GA.pageview(page);
  };

  render() {
    return (
      <Router history={this.state.history}>
        <main className="app">
          <Header lang={this.state.lang} />
          <section className="page">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/story" component={Page} />
              <Route exact path="/why" component={Page} />
              <Route exact path="/training" component={Page} />
              <Route exact path="/academy" component={Page} />
              <Route exact path="/programs" component={Page} />
              <Route exact path="/mentor" component={Page} />
              <Route exact path="/stories" component={Page} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/blog/:id" component={BlogArticle} />
              <Route exact path="/about" component={Page} />
              <Route exact path="/contact" component={Page} />
              <Route exact path="/book" component={Page} />
              <Route exact path="/podcast" component={Page} />
              <Route exact path="/privacy" component={Page} />
              <Route exact path="/en" component={Home} />
              <Route exact path="/en/story" component={Page} />
              <Route exact path="/en/why" component={Page} />
              <Route exact path="/en/training" component={Page} />
              <Route exact path="/en/programs" component={Page} />
              <Route exact path="/en/mentor" component={Page} />
              <Route exact path="/en/stories" component={Page} />
              <Route exact path="/en/blog" component={Blog} />
              <Route exact path="/en/blog/:id" component={BlogArticle} />
              <Route exact path="/en/about" component={Page} />
              <Route exact path="/en/contact" component={Page} />
              <Route exact path="/en/book" component={Page} />
              <Route exact path="/en/podcast" component={Page} />
              <Route exact path="/en/privacy" component={Page} />
            </Switch>
          </section>
          <Footer lang={this.state.lang} />
        </main>
      </Router>
    );
  }
}

export default App;
